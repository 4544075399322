import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomCheckBox, CustomCircularProgress, CustomDatePicker, CustomTextField, FilledButton, HollowButton } from '../../Components/CustomMui/CustomMui'
import { ICommittee, IDonation, IMember } from '../../Helpers/Interfaces';
import '../../Modal.css'
import dayjs, { Dayjs } from 'dayjs';
import { Autocomplete, Box, Chip, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, OutlinedInput, Select, Step, StepLabel, Stepper, TextField } from '@mui/material';
import axios from 'axios';
import { BaseUrl, toBase64, uploadToS3 } from '../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';
import { UserContext } from '../../Store/Store';
import { BranchTypes, MemberStatusTypes, TitleTypes, UserContextType } from '../../Helpers/Types';
import CircleIcon from '@mui/icons-material/Circle';

type ViewEditModalProps = {
  show: boolean;
  onHide: CallableFunction;
  selectedMember: IMember | null;
  reloadMembers: CallableFunction;
}

function ViewEditModal(props: Readonly<ViewEditModalProps>) {
  const { user } = useContext(UserContext) as UserContextType;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [members, setMembers] = useState([] as IMember[])
  const [status, setStatus] = useState("Active" as string);
  const [profilePic, setProfilePic] = useState(null as File | null);
  const [profilePicPreview, setProfilePicPreview] = useState("" as string);
  const [proposer, setProposer] = useState(null as IMember | null);
  const [seconder, setSeconder] = useState(null as IMember | null);
  const [joinedDate, setJoinedDate] = useState(null as Dayjs | null);
  const [title, setTitle] = useState("Mr" as TitleTypes);
  const [firstname, setFirstname] = useState("" as string);
  const [lastname, setLastname] = useState("" as string);
  const [otherName, setOtherName] = useState("" as string);
  const [dob, setDob] = useState(null as Dayjs | null);
  const [nic_passport, setNicPassport] = useState("" as string);
  const [email, setEmail] = useState("" as string);
  const [landline, setLandline] = useState("" as string);
  const [mobile1, setMobile1] = useState("" as string);
  const [mobile2, setMobile2] = useState("" as string);
  const [fax, setFax] = useState("" as string);
  const [address, setAddress] = useState("" as string);
  const [postalCode, setPostalCode] = useState("" as string);
  const [city, setCity] = useState("" as string);
  const [isLocalMailing, setIsLocalMailing] = useState(true as boolean);
  const [isForeignResident, setIsForeignResident] = useState(false as boolean);
  const [foreignAddress, setForeignAddress] = useState("" as string);
  const [foreignPostalCode, setForeignPostalCode] = useState("" as string);
  const [foreignCity, setForeignCity] = useState("" as string);
  const [country, setCountry] = useState("" as string);
  const [isForeignMailing, setIsForeignMailing] = useState(false as boolean);
  const [occupation, setOccupation] = useState("" as string);
  const [company, setCompany] = useState("" as string);
  const [companyAddress, setCompanyAddress] = useState("" as string);
  const [companyPostalCode, setCompanyPostalCode] = useState("" as string);
  const [companyCity, setCompanyCity] = useState("" as string);
  const [companyCountry, setCompanyCountry] = useState("" as string);
  const [indexNo, setIndexNo] = useState("" as string);
  const [admissionYear, setAdmissionYear] = useState(null as Dayjs | null);
  const [leavingYear, setLeavingYear] = useState(null as Dayjs | null);
  const [branch, setBranch] = useState([] as BranchTypes[]);
  const [juniorTransferDate, setJuniorTransferDate] = useState(null as Dayjs | null);
  const [documents, setDocuments] = useState([] as string[]);
  const [donations, setDonations] = useState([] as IDonation[]);
  const [designations, setDesignations] = useState([] as ICommittee[]);

  useEffect(() => {
    getMembers()
    if (props.selectedMember) {
      loadData()
      getPositionsHeld(props.selectedMember._id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedMember])

  async function getMembers() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'member').then((response) => {
      setMembers(response.data.result)
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  async function getPositionsHeld(memberId: string) {
    setIsLoading(true)
    await axios.get(BaseUrl + 'committee/member/' + memberId).then((response) => {
      setDesignations(response.data.result)
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  async function loadData() {
    setStatus(props.selectedMember?.status as MemberStatusTypes)
    setTitle(props.selectedMember?.title as TitleTypes)
    setFirstname(props.selectedMember?.firstname as string)
    setLastname(props.selectedMember?.lastname as string)
    setOtherName(props.selectedMember?.otherName as string)
    setDob(dayjs(props.selectedMember?.dob) as Dayjs | null);
    setNicPassport(props.selectedMember?.nic_passport as string)
    setEmail(props.selectedMember?.email as string)
    setLandline(props.selectedMember?.landline as string)
    setMobile1(props.selectedMember?.mobile1 as string)
    setMobile2(props.selectedMember?.mobile2 as string)
    setFax(props.selectedMember?.fax as string)
    setAddress(props.selectedMember?.address as string)
    setPostalCode(props.selectedMember?.postalCode as string)
    setCity(props.selectedMember?.city as string)
    setIsLocalMailing(props.selectedMember?.isLocalMailing as boolean)
    setIsForeignResident(props.selectedMember?.isForeignResident as boolean)
    setForeignAddress(props.selectedMember?.foreignAddress as string)
    setForeignPostalCode(props.selectedMember?.foreignPostalCode as string)
    setForeignCity(props.selectedMember?.foreignCity as string)
    setCountry(props.selectedMember?.country as string)
    setIsForeignMailing(props.selectedMember?.isForeignMailing as boolean)
    setOccupation(props.selectedMember?.occupation as string)
    setCompany(props.selectedMember?.company as string)
    setCompanyAddress(props.selectedMember?.companyAddress as string)
    setCompanyPostalCode(props.selectedMember?.companyPostalCode as string)
    setCompanyCity(props.selectedMember?.companyCity as string)
    setCompanyCountry(props.selectedMember?.companyCountry as string)
    setIndexNo(props.selectedMember?.indexNo as string)
    setAdmissionYear(dayjs(props.selectedMember?.admissionYear) as Dayjs | null)
    setLeavingYear(props.selectedMember?.leavingYear as Dayjs | null)
    setBranch(props.selectedMember?.branch as BranchTypes[])
    setJuniorTransferDate(props.selectedMember?.juniorTransferDate as Dayjs | null)
    setProposer(props.selectedMember?.proposer as IMember | null)
    setSeconder(props.selectedMember?.seconder as IMember | null)
    setJoinedDate(dayjs(props.selectedMember?.joinedDate) as Dayjs | null)
    setDocuments(props.selectedMember?.documents as string[])
    getDonations(props.selectedMember?._id)
  }

  async function getDonations(memberId: string | undefined) {
    setIsLoading(true)
    await axios.get(BaseUrl + 'donation/member/' + memberId).then((response) => {
      setDonations(response.data.result)
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleEditMember = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    const data = {
      profilePic: profilePic ? await uploadToS3(profilePic, props.selectedMember?.membershipNo as string, true) : props.selectedMember?.profilePic,
      status, proposer: proposer?._id, seconder: seconder?._id, joinedDate, title, firstname, lastname, otherName, dob, nic_passport, email, landline, mobile1, mobile2, fax, address, postalCode, city, isLocalMailing, isForeignResident, foreignAddress, foreignPostalCode, foreignCity, country, isForeignMailing, occupation, company, companyAddress, companyPostalCode, companyCity, companyCountry, indexNo, admissionYear, leavingYear, branch, juniorTransferDate,
    }

    await axios.put(BaseUrl + 'member/member/' + props.selectedMember?._id, data).then((response) => {
      toast.success(response.data.message)
      props.reloadMembers()
      closeModal()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  function closeModal() {
    clearFields();
    props.onHide();
  }

  function clearFields() {
    setStatus("Active")
    setProfilePicPreview("")
    setProfilePic(null)
    setTitle("Mr")
    setFirstname("")
    setLastname("")
    setOtherName("")
    setNicPassport("")
    setEmail("")
    setLandline("")
    setMobile1("")
    setMobile2("")
    setFax("")
    setAddress("")
    setPostalCode("")
    setCity("")
    setIsLocalMailing(true)
    setIsForeignResident(false)
    setForeignAddress("")
    setForeignPostalCode("")
    setForeignCity("")
    setCountry("")
    setIsForeignMailing(false)
    setOccupation("")
    setCompany("")
    setCompanyAddress("")
    setCompanyPostalCode("")
    setCompanyCity("")
    setCompanyCountry("")
    setIndexNo("")
    setAdmissionYear(null)
    setLeavingYear(null)
    setBranch([])
    setJuniorTransferDate(null)
    setProposer(null)
    setSeconder(null)
    setJoinedDate(null)
  }

  return (
    <Modal show={props.show} size='xl' onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <div className='w-75 justify-between flex-wrap'>
          <h3>View / Edit Member</h3>
          {props.selectedMember?.status !== "Pending" && (
            <CustomTextField label="Status" select value={status} onChange={(e) => setStatus(e.target.value)} >
              <MenuItem value='Active'>
                Active <CircleIcon className='ms-2' fontSize='small' style={{ color: "var(--green)" }} />
              </MenuItem>
              <MenuItem value='Suspended'>
                Suspended <CircleIcon className='ms-2' fontSize='small' style={{ color: "var(--yellow)" }} />
              </MenuItem>
              <MenuItem value='Deceased'>
                Deceased <CircleIcon className='ms-2' fontSize='small' style={{ color: "var(--red-200)" }} />
              </MenuItem>
            </CustomTextField>
          )}
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleEditMember}>
          <div className='row p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Membership Details</h5>
            <div className='row'>
              <div className='col-xl-3 justify-content-center'>
                <img className="img-fluid profile-pic-upload"
                  src={
                    profilePicPreview !== "" ? profilePicPreview
                      : props.selectedMember?.profilePic ? props.selectedMember?.profilePic
                        : '/images/avatar.jpg'
                  } alt="profile"
                  onClick={() => document.getElementById("profile_pic")?.click()}
                />
                <input style={{ display: "none" }}
                  type="file" id="profile_pic" accept="image/png, image/jpeg, image/* " capture="user"
                  onChange={(e) => {
                    if (e.target.files) {
                      setProfilePic(e.target.files[0])
                      toBase64(e.target.files[0]).then((url) => {
                        setProfilePicPreview(url as string)
                      });
                    }
                  }}
                />
              </div>
              <div className='col-xl-9'>
                <div className='row'>
                  <div className='col-xl-6'>
                    <CustomTextField label="Membership No" type='text' value={props.selectedMember?.membershipNo} disabled />
                  </div>
                  <div className='col-xl-6'>
                    <CustomDatePicker label="Joined Date" value={joinedDate} defaultValue={dayjs(props.selectedMember?.joinedDate)}
                      onChange={(newValue: React.SetStateAction<Dayjs | null>) => setJoinedDate(newValue)}
                    />
                  </div>
                  <div className='col-xl-6'>
                    <Autocomplete
                      options={members}
                      getOptionLabel={(option) => option.membershipNo + " - " + option.firstname + " " + option.lastname}
                      value={proposer}
                      onChange={(event, newValue) => setProposer(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params} required fullWidth type='text'
                          label="Proposer" margin='normal' variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className='col-xl-6'>
                    <Autocomplete
                      options={members}
                      getOptionLabel={(option) => option.membershipNo + " - " + option.firstname + " " + option.lastname}
                      value={seconder}
                      onChange={(event, newValue) => setSeconder(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params} required fullWidth type='text'
                          label="Seconder" margin='normal' variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Personal Details</h5>
            <div className='col-xl-2'>
              <CustomTextField select label="Title" value={title}
                onChange={(e) => setTitle(e.target.value as TitleTypes)}
              >
                <MenuItem value='Mr'>Mr</MenuItem>
                <MenuItem value='Rev'>Rev</MenuItem>
                <MenuItem value='Ven'>Ven</MenuItem>
                <MenuItem value='Dr'>Dr</MenuItem>
                <MenuItem value='Prof'>Prof</MenuItem>
              </CustomTextField>
            </div>
            <div className='col-xl-5'>
              <CustomTextField label="First Name" type='name' value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Last Name" type='name' value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Other Name" type='name' value={otherName} required={false}
                onChange={(e) => setOtherName(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="NIC/Passport" type='text' value={nic_passport}
                onChange={(e) => setNicPassport(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomDatePicker
                label="Date of Birth" value={dob} defaultValue={dayjs(props.selectedMember?.dob)}
                onChange={(newValue: React.SetStateAction<Dayjs | null>) => setDob(newValue)}
              />
            </div>
          </div>
          <div className='row mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Contact Details</h5>
            <div className='col-xl-4'>
              <CustomTextField label="Email" type='email' value={email} required={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField label="Landline" type='tel' value={landline} required={false}
                onChange={(e) => setLandline(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Mobile 1" type='tel' value={mobile1}
                onChange={(e) => setMobile1(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Mobile 2" type='tel' value={mobile2} required={false}
                onChange={(e) => setMobile2(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Fax" type='text' value={fax} required={false}
                onChange={(e) => setFax(e.target.value)}
              />
            </div>
          </div>
          <div className='row align-items-center mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Local Residential Details</h5>
            <div className='col-xl-12'>
              <CustomTextField label="Address" multiline rows={3} value={address} required={false}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Postal code" type='text' value={postalCode} required={false}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="City" type='text' value={city} required={false}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <FormGroup>
                <FormControlLabel
                  control={<CustomCheckBox checked={isLocalMailing}
                    onChange={(e) => { setIsForeignMailing(!e.target.checked); setIsLocalMailing(e.target.checked) }}
                  />}
                  label="Mailing address"
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup>
            <FormControlLabel
              control={<CustomCheckBox checked={isForeignResident}
                onChange={(e) => setIsForeignResident(e.target.checked)}
              />}
              className='mt-3'
              label="I am a foreign resident"
            />
          </FormGroup>
          {isForeignResident && (
            <div className='row align-items-center p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
              <h5>Foreign Residential Details</h5>
              <div className='col-xl-12'>
                <CustomTextField label="Address" multiline rows={3} value={foreignAddress}
                  onChange={(e) => setForeignAddress(e.target.value)} required={false}
                />
              </div>
              <div className='col-xl-4'>
                <CustomTextField
                  label="Postal code" type='text' value={foreignPostalCode} required={false}
                  onChange={(e) => setForeignPostalCode(e.target.value)}
                />
              </div>
              <div className='col-xl-4'>
                <CustomTextField
                  label="City" type='text' value={foreignCity} required={false}
                  onChange={(e) => setForeignCity(e.target.value)}
                />
              </div>
              <div className='col-xl-4'>
                <CustomTextField
                  label="Country" type='text' value={country} required={false}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
              <div className='col-xl-4'>
                <FormGroup>
                  <FormControlLabel
                    control={<CustomCheckBox checked={isForeignMailing}
                      onChange={(e) => { setIsLocalMailing(!e.target.checked); setIsForeignMailing(e.target.checked) }}
                    />}
                    label="Mailing address"
                  />
                </FormGroup>
              </div>
            </div>
          )}
          <div className='row align-items-center mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Occupation Details</h5>
            <div className='col-xl-4'>
              <CustomTextField
                label="Occupation" type='text' value={occupation}
                onChange={(e) => setOccupation(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Company name" type='text' value={company} required={false}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div><div className='col-xl-12'>
              <CustomTextField label="Company Address" multiline rows={3} value={companyAddress} required={false}
                onChange={(e) => setCompanyAddress(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Company Postal code" type='text' value={companyPostalCode} required={false}
                onChange={(e) => setCompanyPostalCode(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Company City" type='text' value={companyCity} required={false}
                onChange={(e) => setCompanyCity(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Company Country" type='text' value={companyCountry} required={false}
                onChange={(e) => setCompanyCountry(e.target.value)}
              />
            </div>
          </div>
          <div className='row align-items-center mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>College Details</h5>
            <div className='col-xl-4'>
              <CustomTextField label="Index No" type='text' value={indexNo}
                onChange={(e) => setIndexNo(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomDatePicker
                label="Year of admission" value={admissionYear} views={['year']} defaultValue={dayjs(props.selectedMember?.admissionYear)}
                onChange={(newValue: React.SetStateAction<Dayjs | null>) => setAdmissionYear(newValue)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomDatePicker
                label="Year of leaving" value={leavingYear} views={['year']} defaultValue={dayjs(props.selectedMember?.leavingYear)}
                onChange={(newValue: React.SetStateAction<Dayjs | null>) => setLeavingYear(newValue)}
              />
            </div>
            <div className='col-xl-4'>
              <FormControl fullWidth required margin='normal'>
                <InputLabel>Branch Attended</InputLabel>
                <Select
                  multiple value={branch} fullWidth required
                  onChange={(e) => setBranch(e.target.value as BranchTypes[])}
                  input={<OutlinedInput label="Branch Attended" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  <MenuItem value='Town'>Town</MenuItem>
                  <MenuItem value='Nalluruwa'>Nalluruwa</MenuItem>
                  <MenuItem value='Wekada'>Wekada</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='row align-items-center mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Junior OBA Details</h5>
            <div className='col-xl-4'>
              <CustomDatePicker
                label="Transfer date" value={juniorTransferDate} defaultValue={dayjs(props.selectedMember?.juniorTransferDate)}
                onChange={(newValue: React.SetStateAction<Dayjs | null>) => setJuniorTransferDate(newValue)}
              />
            </div>
          </div>
          <div className='row mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Added and approved by</h5>
            <div className='col-xl-6'>
              <Autocomplete
                options={members}
                getOptionLabel={(option) => option.membershipNo + " - " + option.firstname + " " + option.lastname}
                value={props.selectedMember?.addedBy} readOnly
                renderInput={(params) => (
                  <TextField {...params} fullWidth type='text' label="Added By" margin='normal' variant="outlined" />
                )}
              />
            </div>
            <div className='col-xl-6'>
              <Autocomplete
                options={members}
                getOptionLabel={(option) => option.membershipNo + " - " + option.firstname + " " + option.lastname}
                value={props.selectedMember?.approvedBy} readOnly
                renderInput={(params) => (
                  <TextField {...params} fullWidth type='text' label="Approved by" margin='normal' variant="outlined" />
                )}
              />
            </div>
          </div>
          <div className='row mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Documents</h5>
            <ul>
              {documents.map((document: string, index: number) => (
                <li key={index} className='preview mb-3'>
                  <div className='justify-between'>
                    <a className='me-5' href={document}>{document}</a>
                    {/* <IconButton onClick={() => setDocuments(documents.filter((element: any) => element !== document))}>
                        <Cancel />
                      </IconButton> */}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className='row mt-3'>
            {designations.length > 0 && (
              <div className='col-xl-4 p-3 mb-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
                <h5>Positions</h5>
                <Stepper orientation="vertical">
                  {designations.map((designation, index) => (
                    <Step key={index}>
                      <StepLabel optional={<small>{new Date(designation?.dateAppointed).toLocaleDateString()}</small>}>
                        {designation.designation}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            )}
            {donations.length > 0 && (
              <div className='col-xl-8 p-3 mb-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
                <h5>Donations / Contributions</h5>
                <div className="table-responsive">
                  <table className="table table-hover mt-4 align-middle">
                    <thead>
                      <tr>
                        <th>Project</th>
                        <th>Contribution</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {donations.map((donation: IDonation, index: number) =>
                        <tr key={index}>
                          <td>{donation.projectId.name}</td>
                          <td>{donation.contributionType}</td>
                          <td>Rs {donation.amount.toFixed(2)}</td>
                          <td>{donation.description}</td>
                          <td>{new Date(donation.createdAt).toLocaleDateString()}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className='text-end mt-3'>
            <div>
              <HollowButton className='me-2' onClick={() => closeModal()}>Close</HollowButton>
              {(user.userRole === "Admin" || user.userRole === "Super Admin") && (
                <FilledButton type='submit'>
                  {isLoading ? <CustomCircularProgress /> : "Save Changes"}
                </FilledButton>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default ViewEditModal
import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomCircularProgress, CustomDatePicker, CustomTextField, FilledButton, HollowButton } from '../../Components/CustomMui/CustomMui'
import '../../Modal.css'
import axios from 'axios';
import { BaseUrl } from '../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';
import { UserContextType } from '../../Helpers/Types';
import { UserContext } from '../../Store/Store';
import { Dayjs } from 'dayjs';

type AddProjectModalProps = {
  show: boolean;
  onHide: CallableFunction;
  reloadProjects: CallableFunction;
}

function AddProjectModal(props: Readonly<AddProjectModalProps>) {
  const {user} = useContext(UserContext) as UserContextType;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [name, setName] = useState("" as string);
  const [estimatedAmount, setEstimatedAmount] = useState(0 as number);
  const [startDate, setStartDate] = useState(null as Dayjs | null);

  const handleAddProject = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    const data = {
      name,
      estimatedAmount,
      addedBy: user._id,
      startDate
    }

    await axios.post(BaseUrl + 'project/', data).then((res) => {
      toast.success(res.data.message)
      clearFields()
      props.reloadProjects();
      closeModal()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  function closeModal() {
    clearFields();
    props.onHide();
  }

  function clearFields() {
    setName("");
    setEstimatedAmount(0);
    setStartDate(null);
  }

  return (
    <Modal show={props.show} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <h3>Add Project</h3>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleAddProject}>
          <CustomTextField type='text' label="Name" value={name} onChange={(e) => setName(e.target.value)} />
          <CustomTextField
            type='number' label="Estimated amount" value={estimatedAmount} onChange={(e) => setEstimatedAmount(parseFloat(e.target.value))}
            InputProps={{ inputProps: { min: 10 } }}
          />
          <CustomDatePicker
            label="Start date" value={startDate}
            onChange={(newValue: React.SetStateAction<Dayjs | null>) => setStartDate(newValue)}
          />
          <div className='text-end mt-5'>
            <HollowButton onClick={() => closeModal()}>Cancel</HollowButton>
            <FilledButton type='submit' className='ms-3'>{isLoading ? <CustomCircularProgress /> : "Add"}</FilledButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddProjectModal
import { LinearProgress } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { FilledButton, HollowButton } from '../../../Components/CustomMui/CustomMui'
import { BaseUrl } from '../../../Helpers/HelperFunctions'
import { IDonation } from '../../../Helpers/Interfaces'
import { Add, ArrowBack } from '@mui/icons-material';
import AddDonationModal from './AddDonationModal'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../../Store/Store'
import { UserContextType } from '../../../Helpers/Types'
import { useNavigate, useParams } from 'react-router-dom'

function Donations() {
  const { user } = useContext(UserContext) as UserContextType;
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [donations, setDonations] = useState([] as IDonation[])
  const [showAddModal, setShowAddModal] = useState(false as boolean)
  const { projectId } = useParams()

  useEffect(() => {
    getDonationsByProject()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getDonationsByProject() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'donation/project/' + projectId).then((response) => {
      setDonations(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <>
      <div className='page-wrapper'>
        <div className='justify-between flex-wrap'>
          <div className='justify-between mb-3'>
            <HollowButton startIcon={<ArrowBack/>} onClick={()=>navigate(-1)} className='me-2'>Back</HollowButton>
            <h2>{donations[0]?.projectId.name} - Donations</h2>
          </div>
          {user.userRole === "Super Admin" && (
            <FilledButton onClick={() => setShowAddModal(true)} endIcon={<Add />} className='mb-3'>Add Donation</FilledButton>
          )}
        </div>
        {isLoading ? (<LinearProgress />) : (
          <div className="table-responsive">
            <table className="table table-hover mt-4 align-middle">
              <thead>
                <tr>
                  <th>Membership No</th>
                  <th>Name</th>
                  <th>Contribution</th>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {donations.map((donation: IDonation, index: number) =>
                  <tr key={index}>
                    <td>{donation.memberId.membershipNo}</td>
                    <td>
                      {donation.memberId.title + ". " + donation.memberId.firstname + " " + donation.memberId.lastname}
                    </td>
                    <td>{donation.contributionType}</td>
                    <td>Rs {donation.amount.toFixed(2)}</td>
                    <td>{donation.description}</td>
                    <td>{new Date(donation.createdAt).toLocaleDateString()}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div >
      <AddDonationModal show={showAddModal} projectId={projectId as string} reloadDonations={() => getDonationsByProject()} onHide={() => setShowAddModal(false)} />
    </>
  )
}

export default Donations
import { InputAdornment, LinearProgress } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { CustomTextField, FilledButton } from '../../Components/CustomMui/CustomMui'
import { BaseUrl, getSearchResults } from '../../Helpers/HelperFunctions'
import { IProject } from '../../Helpers/Interfaces'
import { Add, Search } from '@mui/icons-material';
import AddProjectModal from './AddProjectModal'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../Store/Store'
import { UserContextType } from '../../Helpers/Types'
import { useNavigate } from 'react-router-dom'

function Projects() {
  const { user } = useContext(UserContext) as UserContextType;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [projects, setProjects] = useState([] as IProject[])
  const [searchedProjects, setSearchedProjects] = useState([] as IProject[])
  const [showAddModal, setShowAddModal] = useState(false as boolean)
  const navigate = useNavigate()

  useEffect(() => {
    getProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getProjects() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'project/').then((response) => {
      setProjects(response.data.result)
      setSearchedProjects(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleSearch = (searchKeyWord: string) => {
    const searchResults = getSearchResults(searchKeyWord, projects)
    setSearchedProjects(searchResults)
  }

  return (
    <>
      <div className='page-wrapper'>
        <div className='justify-between flex-wrap mb-3'>
          <h2>Projects</h2>
          {user.userRole === "Super Admin" && (
            <FilledButton onClick={() => setShowAddModal(true)} endIcon={<Add />}>Add Project</FilledButton>
          )}
        </div>
        <CustomTextField label="Search" size='small' type='search' fullWidth={false}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            )
          }}
        />
        {isLoading ? (<LinearProgress />) : (
          <div className="table-responsive">
            <table className="table table-hover mt-4 align-middle">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Started Date</th>
                  <th>Estimated Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {searchedProjects.map((project: IProject, index: number) =>
                  <tr key={index} className='cursor-pointer' onClick={() => navigate(`${project._id}`)}>
                    <td>{project.name}</td>
                    <td>{new Date(project.startDate).toLocaleDateString()}</td>
                    <td>Rs {project.estimatedAmount.toFixed(2)}</td>
                    <td className="text-primary">View Contributions</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div >
      <AddProjectModal show={showAddModal} reloadProjects={() => getProjects()} onHide={() => setShowAddModal(false)} />
    </>
  )
}

export default Projects
import { Button, ButtonProps, Checkbox, CheckboxProps, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import './CustomMui.css'

export function FilledButton(props: ButtonProps) {
  return (
    <Button onClick={props.onClick} disableElevation size='large' variant='contained' fullWidth={props.fullWidth} type={props.type} startIcon={props.startIcon} disabled={props.disabled}
      endIcon={props.endIcon} className={props.className + ' primary-btn' || 'primary-btn'}>
      {props.children}
    </Button>
  );
};

export function HollowButton(props: ButtonProps) {
  return (
    <Button
      onClick={props.onClick} disableElevation startIcon={props.startIcon} endIcon={props.endIcon} fullWidth={props.fullWidth} className={props.className + ' secondary-btn' || 'secondary-btn'} variant='outlined' size='large'
    >
      {props.children}
    </Button>
  );
};

export function CustomTextField(props: TextFieldProps) {
  return (
    <TextField fullWidth={props.fullWidth !== false} required={props.required !== false} select={props.select} defaultValue={props.defaultValue} variant='outlined' margin='normal'
      helperText={props.helperText} size={props.size} placeholder={props.placeholder} label={props.label} type={props.type} value={props.value} disabled={props.disabled}
      onChange={props.onChange} inputProps={props.inputProps} InputProps={props.InputProps} multiline={props.multiline} rows={props.rows} className={props.className}>
      {props.children}
    </TextField>
  );
};

export function CustomCheckBox(props: Readonly<CheckboxProps>) {
  return (
    <Checkbox size='small' checked={props.checked} onChange={props.onChange} icon={<CheckBoxOutlineBlankOutlinedIcon className='checkbox' />}
      checkedIcon={<SquareRoundedIcon className='checkbox-check' />} />
  );
};

export function CustomDatePicker(props: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker slotProps={{ textField: { fullWidth: true, margin: "normal", required: props.required !== false, size: props.size } }} disableFuture label={props.label} className={props.className} onChange={props.onChange} views={props.views} defaultValue={props.defaultValue} />
    </LocalizationProvider>
  );
};

export function CustomCircularProgress() {
  return (
    <CircularProgress color='inherit' size={24} />
  );
};